import { bootstrapApplication } from '@angular/platform-browser';
import * as Sentry from '@sentry/angular';

import { isDevMode } from '@angular/core';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import { environment } from './environments/environment';

Sentry.init({
  ...environment.sentry,
  integrations: [Sentry.httpClientIntegration()],
  /**
   * This option is required for capturing headers and cookies.
   */
  sendDefaultPii: true,
  beforeSend: (event) => {
    if (isDevMode()) {
      console.warn({
        message: 'Logging Sentry event instead of sending to Sentry',
        event,
      });
      return null;
    }
    return event;
  },
});

bootstrapApplication(AppComponent, appConfig).catch((err) =>
  console.error(err)
);
