import { Inject, Injectable } from '@angular/core';
import * as fromSeriousSystem from '@serious-system';
import { firstValueFrom } from 'rxjs';
import * as fromGenerated from '../../../../_generated';

@Injectable()
export class DeepgramApiKeyService
  implements fromSeriousSystem.VoiceToTextApiKeyServiceInterface
{
  constructor(
    @Inject(fromGenerated.SpeechToTextDeepgramService)
    private readonly deepgramService: fromGenerated.SpeechToTextDeepgramService
  ) {}

  async getApiKey(): Promise<string> {
    const storedApiKey = this.getStoredApiKey();
    const isApiKeyValid =
      storedApiKey && (await this.isApiKeyValid(storedApiKey.id));

    if (isApiKeyValid) {
      return storedApiKey.key;
    }

    return this.generateAndStoreNewApiKey();
  }

  private getStoredApiKey(): fromGenerated.SpeechToTextGenerateKeyView | null {
    const storedKey = localStorage.getItem('deepgramApiKey');
    return storedKey
      ? (JSON.parse(storedKey) as fromGenerated.SpeechToTextGenerateKeyView)
      : null;
  }

  private async isApiKeyValid(keyId: string): Promise<boolean> {
    try {
      const response = await firstValueFrom(
        this.deepgramService.deepgramControllerGetApiKey(keyId)
      );
      return !!response.id;
    } catch (error) {
      return false;
    }
  }

  private async generateAndStoreNewApiKey(): Promise<string> {
    try {
      const newApiKey = await firstValueFrom(
        this.deepgramService.deepgramControllerGenerateApiKey()
      );
      localStorage.setItem('deepgramApiKey', JSON.stringify(newApiKey));
      return newApiKey.key;
    } catch (error) {
      console.error('Failed to generate Deepgram API key:', error);
      throw error;
    }
  }
}
