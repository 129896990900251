import { Component, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { environment } from '../environments/environment';
import { PlaygroundPage } from './playground.page';

@Component({
  standalone: true,
  imports: [PlaygroundPage, RouterModule],
  selector: 'squadbox-root',
  template: ` <router-outlet></router-outlet> `,
  styles: [``],
})
export class AppComponent {
  private readonly translateService = inject(TranslateService);

  constructor() {
    this.translateService.addLangs(['en', 'fr']);
    this.translateService.setDefaultLang(environment.defaultLanguage);
    this.translateService.use(environment.defaultLanguage);
  }
}
