import { provideHttpClient, withInterceptors } from '@angular/common/http';
import {
  ApplicationConfig,
  ErrorHandler,
  importProvidersFrom,
  isDevMode,
  makeEnvironmentProviders,
  provideZoneChangeDetection,
} from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideRouter, withViewTransitions } from '@angular/router';
import { provideEffects } from '@ngrx/effects';
import { provideState, provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { TranslateModule } from '@ngx-translate/core';
import * as Sentry from '@sentry/angular';
import { MarkdownModule } from 'ngx-markdown';

import { environment } from '../environments/environment';
import { provideTranslation } from './app.i18n';
import { appRoutes } from './app.routes';

import * as fromGenerated from './_generated';
import * as fromAuth from './auth';
import * as fromFeatureFlags from './feature-flags';

export const appConfig: ApplicationConfig = {
  providers: [
    provideEffects([
      fromAuth.AuthEffects,
      fromFeatureFlags.FeatureFlagsEffects,
    ]),
    provideStore(),
    provideState(fromAuth.authFeature),
    provideState(fromFeatureFlags.featureFlagsFeature),
    provideStoreDevtools({ maxAge: 25, logOnly: !isDevMode() }),
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(appRoutes, withViewTransitions()),
    provideHttpClient(withInterceptors([fromAuth.authInterceptor])),
    importProvidersFrom([TranslateModule.forRoot(provideTranslation())]),
    importProvidersFrom([BrowserAnimationsModule]),
    importProvidersFrom([MarkdownModule.forRoot()]),
    importProvidersFrom([
      fromGenerated.ApiModule.forRoot(
        () =>
          new fromGenerated.Configuration({
            basePath: environment.appApiUrl,
            withCredentials: true,
          })
      ),
    ]),
    makeEnvironmentProviders([
      {
        provide: ErrorHandler,
        useValue: Sentry.createErrorHandler(),
      },
      fromFeatureFlags.provideLaunchDarklyClient(),
      fromFeatureFlags.provideLaunchDarklyInitializer(),
    ]),
  ],
};
