import type * as SpeechSDKType from 'microsoft-cognitiveservices-speech-sdk/distrib/lib/microsoft.cognitiveservices.speech.sdk';
import '../../../../../../assets/microsoft-cognitiveservices-speech-sdk/microsoft.cognitiveservices.speech.sdk.bundle-min';

declare global {
  interface Window {
    SpeechSDK: {
      AudioConfig: typeof SpeechSDKType.AudioConfig;
      OutputFormat: typeof SpeechSDKType.OutputFormat;
      Recognizer: typeof SpeechSDKType.Recognizer;
      SpeechConfig: typeof SpeechSDKType.SpeechConfig;
      SpeechRecognizer: typeof SpeechSDKType.SpeechRecognizer;
      AutoDetectSourceLanguageConfig: typeof SpeechSDKType.AutoDetectSourceLanguageConfig;
      SpeechRecognitionEventArgs: typeof SpeechSDKType.SpeechRecognitionEventArgs;
      ResultReason: typeof SpeechSDKType.ResultReason;
    };
  }
}
export const {
  AudioConfig,
  OutputFormat,
  Recognizer,
  SpeechConfig,
  SpeechRecognizer,
  AutoDetectSourceLanguageConfig,
  ResultReason,
} = window.SpeechSDK;
