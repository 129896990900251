import { Inject, Injectable } from '@angular/core';
import * as fromSeriousSystem from '@serious-system';
import { DateTime } from 'luxon';
import { firstValueFrom } from 'rxjs';
import * as fromGenerated from '../../../../_generated';

@Injectable()
export class AzureApiKeyService
  implements fromSeriousSystem.VoiceToTextApiKeyServiceInterface
{
  private readonly KEY_STORAGE_NAME = 'azureApiKey';
  // Authentication tokens are valid for 10 minutes. Microsoft limits the validity of the token to 10 minutes.
  private readonly TEN_MINUTES_VALIDITY_DURATION = 10 * 60 * 1000;

  constructor(
    @Inject(fromGenerated.SpeechToTextAzureService)
    private readonly azureService: fromGenerated.SpeechToTextAzureService
  ) {}

  async getApiKey(): Promise<string> {
    const storedApiKey: fromGenerated.SpeechToTextGenerateKeyView | null =
      this.getStoredApiKey();
    const isApiKeyValid =
      storedApiKey && this.isApiKeyValid(storedApiKey.createdAt);

    if (isApiKeyValid) {
      return storedApiKey.key;
    }

    return this.generateAndStoreNewApiKey();
  }

  private getStoredApiKey(): fromGenerated.SpeechToTextGenerateKeyView | null {
    const storedKey = localStorage.getItem(this.KEY_STORAGE_NAME);
    return storedKey
      ? (JSON.parse(storedKey) as fromGenerated.SpeechToTextGenerateKeyView)
      : null;
  }

  private isApiKeyValid(dateTime: string): boolean {
    const keyGenerationTime = DateTime.fromISO(dateTime).toMillis();
    const currentTime = DateTime.utc().toMillis();
    return currentTime - keyGenerationTime < this.TEN_MINUTES_VALIDITY_DURATION;
  }

  private async generateAndStoreNewApiKey(): Promise<string> {
    try {
      const newApiKey = await firstValueFrom(
        this.azureService.azureControllerGenerateApiKey()
      );

      localStorage.setItem(this.KEY_STORAGE_NAME, JSON.stringify(newApiKey));

      return newApiKey.key;
    } catch (error) {
      console.error('Failed to generate Azure API key:', error);
      throw error;
    }
  }
}
